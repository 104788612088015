import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private storage: StorageService,
    private messageService: MessageService,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private http: HttpClient
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user: any) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', 'null');
      }
    });
  }

  private showErrorMessage(error: any) {
    let errorMessage = 'An error occurred during sign in';
    let showRegisterLink = false;

    switch (error.code) {
      case 'auth/wrong-password':
        errorMessage = 'Invalid email or password';
        break;
      case 'auth/user-not-found':
        errorMessage = 'No account found with this email. Would you like to register?';
        showRegisterLink = true;
        break;
      case 'auth/too-many-requests':
        errorMessage = 'Too many failed attempts. Please try again later';
        break;
      case 'auth/invalid-email':
        errorMessage = 'Please enter a valid email address';
        break;
    }

    this.messageService.add({
      severity: 'error',
      summary: 'Sign In Failed',
      detail: errorMessage,
      sticky: true,
      closable: true,
      id: Date.now().toString(),
      data: { showRegisterLink }
    });
  }

  // Sign in with email/password
  async SignIn(email: string, password: string) {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(email, password);
      this.SetUserData(result.user);
      this.ngZone.run(() => {
        this.router.navigate(['a/dashboard']);
      });
    } catch (error: any) {
      this.showErrorMessage(error);
    }
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['ua/verify-email-address']);
      });
  }
  // Reset Forggot password
  async ForgotPassword(passwordResetEmail: string) {
    try {
      // Clear any existing messages
      this.messageService.clear();

      await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      this.messageService.add({
        severity: 'info',
        summary: 'Password Reset Email Sent',
        detail: 'Please check your inbox for further instructions',
        life: 3000
      });
    } catch (error: any) {
      // Map Firebase auth errors to user-friendly messages
      let errorMessage = 'An error occurred while sending reset email';

      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = 'No account found with this email address';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Please enter a valid email address';
          break;
      }

      this.messageService.add({
        severity: 'error',
        summary: 'Password Reset Failed',
        detail: errorMessage,
        life: 3000
      });
    }
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }
  // Sign in with Google
  GoogleAuth(signUpCode?: string) {
    return this.AuthLogin(new auth.GoogleAuthProvider(), signUpCode);
  }
  async AuthLogin(provider: auth.GoogleAuthProvider, signUpCode?: string) {
    try {
      // Get the credential but don't persist it yet
      const credential = await this.afAuth.signInWithPopup(provider);

      if (!credential.user?.email) {
        throw new Error('No email provided from Google account');
      }

      // Check if this is actually a new user
      const isNewUser = credential.additionalUserInfo?.isNewUser ?? false;

      // CASE 1 - New user without code
      if (isNewUser && !signUpCode) {
        // Immediately delete the temporary account
        await credential.user.delete();
        this.messageService.add({
          severity: 'info',
          summary: 'Sign Up Code Required',
          detail: 'Please enter your sign up code to continue',
          data: { showSignUpDialog: true }
        });
        return;
      }

      // CASE 2 - New user with code
      if (isNewUser && signUpCode) {
        try {
          const idToken = await credential.user.getIdToken();

          // Verify signup code with backend BEFORE setting any user data
          const response = await this.http.post<{
            success: boolean,
            invalid_code: boolean,
            redirect_route?: string,
            detail?: string
          }>(`${environment.apiURL}/create-google-account`, {
            code: signUpCode,
            id_token: idToken,
            display_name: credential.user.displayName
          }).toPromise();


          // If verification fails or response is invalid, delete account and throw error
          if (!response || !response.success) {

            await credential.user.delete();
            if (response?.invalid_code) {
              this.messageService.add({
                severity: 'error',
                summary: 'Invalid signup code',
                detail: response?.detail || 'Invalid signup code',
                data: { showSignUpDialog: true }
              });
              return;
            } else {
              throw new Error(response?.detail || 'Invalid signup code or verification failed');
            }
          }

          // Only if verification succeeds, proceed with account setup
          await this.SetUserData(credential.user);
          this.ngZone.run(() => {
            this.router.navigate([response.redirect_route]);
          });

          this.messageService.add({
            severity: 'success',
            summary: 'Welcome!',
            detail: 'Account created successfully'
          });
          return;
        } catch (verificationError) {
          // If anything fails during verification, ensure the account is deleted
          await credential.user.delete();
          throw verificationError;
        }
      }

      // CASE 3 - Existing user
      if (!isNewUser) {
        console.log("existing user", credential.user);
        await this.SetUserData(credential.user);
        this.ngZone.run(() => {
          localStorage.setItem('user', JSON.stringify(credential.user));
          this.router.navigate(['a/dashboard']);
        });
      }

    } catch (error: any) {
      // Don't show error for popup closed by user
      if (error.code === 'auth/popup-closed-by-user') {
        return;
      }

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message || 'An error occurred during sign up'
      });
    }
  }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    // Fetch existing user data from Firestore
    userRef.get().subscribe((doc) => {
      let userData: any;

      if (doc.exists) {
        // If user data exists, retain existing photoURL and displayName
        const existingData = doc.data();
        userData = {
          uid: user.uid,
          email: user.email,
          displayName: existingData?.displayName || user.displayName,
          photoURL: existingData?.photoURL || user.photoURL,
          emailVerified: user.emailVerified,
          sharedExperiments: existingData?.sharedExperiments || {},
          tutorial: existingData?.tutorial ?? true,
          variables: existingData?.variables || {
            plant: {
              timeSeries: {},
              metadata: {}
            },
            env: {
              timeSeries: {},
              metadata: {}
            }
          }
        };
      } else {
        // If no existing user data, use the data from the user object
        userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          sharedExperiments: {},
          tutorial: true,
          variables: {
            plant: {
              timeSeries: {},
              metadata: {}
            },
            env: {
              timeSeries: {},
              metadata: {}
            }
          }
        };
      }

      // Store the user data immediately to local storage for real-time use
      localStorage.setItem('user', JSON.stringify(userData)); // Synchronize local storage with Firestore data
      console.log('User data set in local storage:', JSON.parse(localStorage.getItem('user')!));


      return userRef.set(userData, {
        merge: true,  // Merge with existing data
      });
    });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.storage.clear();
      localStorage.removeItem('user'); // or use localStorage.clear() to remove all entries
      this.router.navigate(['ua/sign-in']);
    });
  }

  // Add new method to verify signup code and create account
  async SignUpWithCode(email: string, password: string, code: string) {
    try {
      // Create the account with backend
      const response: any = await this.http.post(`${environment.apiURL}/create-email-account`, {
        code: code,
        email: email,
        password: password
      }).toPromise();

      if (!response?.success) {
        if (response?.invalid_code) {
          this.messageService.add({
            severity: 'error',
            summary: 'Invalid signup code',
            detail: response?.detail || 'Invalid signup code',
          });
          return;
        }
        this.messageService.add({
          severity: 'error',
          summary: 'Error signing up',
          detail: response?.detail || "Couldn't create an account",
        });
        return;

      }
      // If successful, sign in the user
      const userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);

      // Wait for user data to be set and authentication to be established
      await new Promise<void>((resolve) => {
        const subscription = this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.SetUserData(user);
            subscription.unsubscribe();  // Call unsubscribe() on the subscription object
            resolve();
          }
        });
      });

      // Now that we're sure the user is authenticated, navigate
      this.ngZone.run(() => {
        this.router.navigate([response.redirect_route]);
      });

      this.messageService.add({
        severity: 'success',
        summary: 'Welcome!',
        detail: 'Account created successfully'
      });

    } catch (error: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error?.detail || 'An error occurred during sign up'
      });
    }
  }
}